import { analytics } from "../firebase/firebase";
import { logEvent } from "firebase/analytics";
import { Tz } from "../models/models";

type ViewType = "main";

export const logScreenView = (screenName: ViewType) => {
  logEvent(analytics, "screen_view", { firebase_screen: screenName, firebase_screen_class: screenName });
};

export const logAddTz = (tz: Tz) => {
  logEvent(analytics, "add_tz", {
    countryName: tz.countryName,
  });
};

export const logReorderTz = () => {
  logEvent(analytics, "reorder_tz");
};

export const logDeleteTz = (tz: Tz) => {
  logEvent(analytics, "delete_tz", {
    countryName: tz.countryName,
  });
};

// export const logError = (errorType: string) => {
//   logEvent(analytics, "error", { error_type: errorType });
// };
