import tzs from "../models/tz";
import { hmm2minute, minute2hhmm } from "../views/timeFormatter";
import { Tz } from "../models/models";
import { TzTimeClass } from "../models/tzTime";

const idsSeparator = ",";

export function tzs2query(t: TzTimeClass): { ids?: string; time?: string } {
  if (t.getTzDataList().length === 0) return {};

  return {
    ids: t.tzs.map((x) => x.id).join(idsSeparator),
    time: minute2hhmm(t.getTzMinute(0), ""),
  };
}

export function queryToTzTimeClass(params: URLSearchParams): TzTimeClass {
  const ids = params.get("ids");
  const time = params.get("time");
  if (ids === null || time === null) return new TzTimeClass();
  const tzs = query2tzs(ids);
  const tz = new TzTimeClass(tzs);
  tz.setTzMinute(0, hmm2minute(time));
  return tz;
}

function query2tzs(ids: string): Tz[] {
  const idList = ids.split(idsSeparator);
  const tzList: Tz[] = [];
  idList.forEach((x) => {
    const i = tzs.findIndex((y) => y.id === x);
    if (i >= 0) {
      tzList.push(tzs[i]);
    }
  });
  return tzList;
}
