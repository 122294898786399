import { Tz } from "./models";

export function findTzByName(name: string): Tz | undefined {
  return tzs.find((x) => x.name === name);
}

const tzs: readonly Tz[] = [
  {
    id: "1",
    countryCode: "AF",
    countryName: "Afghanistan",
    name: "Asia/Kabul",
    utcOffset: 270,
  },
  {
    id: "2",
    countryCode: "AL",
    countryName: "Albania",
    name: "Europe/Tirane",
    utcOffset: 120,
  },
  {
    id: "3",
    countryCode: "DZ",
    countryName: "Algeria",
    name: "Africa/Algiers",
    utcOffset: 60,
  },
  {
    id: "4",
    countryCode: "AS",
    countryName: "American Samoa",
    name: "Pacific/Pago_Pago",
    utcOffset: -660,
  },
  {
    id: "5",
    countryCode: "AD",
    countryName: "Andorra",
    name: "Europe/Andorra",
    utcOffset: 120,
  },
  {
    id: "6",
    countryCode: "AO",
    countryName: "Angola",
    name: "Africa/Luanda",
    utcOffset: 60,
  },
  {
    id: "7",
    countryCode: "AI",
    countryName: "Anguilla",
    name: "America/Anguilla",
    utcOffset: -240,
  },
  {
    id: "8",
    countryCode: "AQ",
    countryName: "Antarctica",
    name: "Antarctica/Casey",
    utcOffset: 660,
  },
  {
    id: "9",
    countryCode: "AQ",
    countryName: "Antarctica",
    name: "Antarctica/Davis",
    utcOffset: 420,
  },
  {
    id: "10",
    countryCode: "AQ",
    countryName: "Antarctica",
    name: "Antarctica/DumontDUrville",
    utcOffset: 600,
  },
  {
    id: "11",
    countryCode: "AQ",
    countryName: "Antarctica",
    name: "Antarctica/Mawson",
    utcOffset: 300,
  },
  {
    id: "12",
    countryCode: "AQ",
    countryName: "Antarctica",
    name: "Antarctica/McMurdo",
    utcOffset: 720,
  },
  {
    id: "13",
    countryCode: "AQ",
    countryName: "Antarctica",
    name: "Antarctica/Palmer",
    utcOffset: -180,
  },
  {
    id: "14",
    countryCode: "AQ",
    countryName: "Antarctica",
    name: "Antarctica/Rothera",
    utcOffset: -180,
  },
  {
    id: "15",
    countryCode: "AQ",
    countryName: "Antarctica",
    name: "Antarctica/Syowa",
    utcOffset: 180,
  },
  {
    id: "16",
    countryCode: "AQ",
    countryName: "Antarctica",
    name: "Antarctica/Troll",
    utcOffset: 120,
  },
  {
    id: "17",
    countryCode: "AQ",
    countryName: "Antarctica",
    name: "Antarctica/Vostok",
    utcOffset: 360,
  },
  {
    id: "18",
    countryCode: "AG",
    countryName: "Antigua and Barbuda",
    name: "America/Antigua",
    utcOffset: -240,
  },
  {
    id: "19",
    countryCode: "AR",
    countryName: "Argentina",
    name: "America/Argentina/Buenos_Aires",
    utcOffset: -180,
  },
  {
    id: "20",
    countryCode: "AR",
    countryName: "Argentina",
    name: "America/Argentina/Catamarca",
    utcOffset: -180,
  },
  {
    id: "21",
    countryCode: "AR",
    countryName: "Argentina",
    name: "America/Argentina/Cordoba",
    utcOffset: -180,
  },
  {
    id: "22",
    countryCode: "AR",
    countryName: "Argentina",
    name: "America/Argentina/Jujuy",
    utcOffset: -180,
  },
  {
    id: "23",
    countryCode: "AR",
    countryName: "Argentina",
    name: "America/Argentina/La_Rioja",
    utcOffset: -180,
  },
  {
    id: "24",
    countryCode: "AR",
    countryName: "Argentina",
    name: "America/Argentina/Mendoza",
    utcOffset: -180,
  },
  {
    id: "25",
    countryCode: "AR",
    countryName: "Argentina",
    name: "America/Argentina/Rio_Gallegos",
    utcOffset: -180,
  },
  {
    id: "26",
    countryCode: "AR",
    countryName: "Argentina",
    name: "America/Argentina/Salta",
    utcOffset: -180,
  },
  {
    id: "27",
    countryCode: "AR",
    countryName: "Argentina",
    name: "America/Argentina/San_Juan",
    utcOffset: -180,
  },
  {
    id: "28",
    countryCode: "AR",
    countryName: "Argentina",
    name: "America/Argentina/San_Luis",
    utcOffset: -180,
  },
  {
    id: "29",
    countryCode: "AR",
    countryName: "Argentina",
    name: "America/Argentina/Tucuman",
    utcOffset: -180,
  },
  {
    id: "30",
    countryCode: "AR",
    countryName: "Argentina",
    name: "America/Argentina/Ushuaia",
    utcOffset: -180,
  },
  {
    id: "31",
    countryCode: "AM",
    countryName: "Armenia",
    name: "Asia/Yerevan",
    utcOffset: 240,
  },
  {
    id: "32",
    countryCode: "AW",
    countryName: "Aruba",
    name: "America/Aruba",
    utcOffset: -240,
  },
  {
    id: "33",
    countryCode: "AU",
    countryName: "Australia",
    name: "Antarctica/Macquarie",
    utcOffset: 600,
  },
  {
    id: "34",
    countryCode: "AU",
    countryName: "Australia",
    name: "Australia/Adelaide",
    utcOffset: 570,
  },
  {
    id: "35",
    countryCode: "AU",
    countryName: "Australia",
    name: "Australia/Brisbane",
    utcOffset: 600,
  },
  {
    id: "36",
    countryCode: "AU",
    countryName: "Australia",
    name: "Australia/Broken_Hill",
    utcOffset: 570,
  },
  {
    id: "37",
    countryCode: "AU",
    countryName: "Australia",
    name: "Australia/Darwin",
    utcOffset: 570,
  },
  {
    id: "38",
    countryCode: "AU",
    countryName: "Australia",
    name: "Australia/Eucla",
    utcOffset: 525,
  },
  {
    id: "39",
    countryCode: "AU",
    countryName: "Australia",
    name: "Australia/Hobart",
    utcOffset: 600,
  },
  {
    id: "40",
    countryCode: "AU",
    countryName: "Australia",
    name: "Australia/Lindeman",
    utcOffset: 600,
  },
  {
    id: "41",
    countryCode: "AU",
    countryName: "Australia",
    name: "Australia/Lord_Howe",
    utcOffset: 630,
  },
  {
    id: "42",
    countryCode: "AU",
    countryName: "Australia",
    name: "Australia/Melbourne",
    utcOffset: 600,
  },
  {
    id: "43",
    countryCode: "AU",
    countryName: "Australia",
    name: "Australia/Perth",
    utcOffset: 480,
  },
  {
    id: "44",
    countryCode: "AU",
    countryName: "Australia",
    name: "Australia/Sydney",
    utcOffset: 600,
  },
  {
    id: "45",
    countryCode: "AT",
    countryName: "Austria",
    name: "Europe/Vienna",
    utcOffset: 120,
  },
  {
    id: "46",
    countryCode: "AZ",
    countryName: "Azerbaijan",
    name: "Asia/Baku",
    utcOffset: 240,
  },
  {
    id: "47",
    countryCode: "BS",
    countryName: "Bahamas",
    name: "America/Nassau",
    utcOffset: -240,
  },
  {
    id: "48",
    countryCode: "BH",
    countryName: "Bahrain",
    name: "Asia/Bahrain",
    utcOffset: 180,
  },
  {
    id: "49",
    countryCode: "BD",
    countryName: "Bangladesh",
    name: "Asia/Dhaka",
    utcOffset: 360,
  },
  {
    id: "50",
    countryCode: "BB",
    countryName: "Barbados",
    name: "America/Barbados",
    utcOffset: -240,
  },
  {
    id: "51",
    countryCode: "BY",
    countryName: "Belarus",
    name: "Europe/Minsk",
    utcOffset: 180,
  },
  {
    id: "52",
    countryCode: "BE",
    countryName: "Belgium",
    name: "Europe/Brussels",
    utcOffset: 120,
  },
  {
    id: "53",
    countryCode: "BZ",
    countryName: "Belize",
    name: "America/Belize",
    utcOffset: -360,
  },
  {
    id: "54",
    countryCode: "BJ",
    countryName: "Benin",
    name: "Africa/Porto-Novo",
    utcOffset: 60,
  },
  {
    id: "55",
    countryCode: "BM",
    countryName: "Bermuda",
    name: "Atlantic/Bermuda",
    utcOffset: -180,
  },
  {
    id: "56",
    countryCode: "BT",
    countryName: "Bhutan",
    name: "Asia/Thimphu",
    utcOffset: 360,
  },
  {
    id: "57",
    countryCode: "BO",
    countryName: "Bolivia, Plurinational State of",
    name: "America/La_Paz",
    utcOffset: -240,
  },
  {
    id: "58",
    countryCode: "BQ",
    countryName: "Bonaire, Sint Eustatius and Saba",
    name: "America/Kralendijk",
    utcOffset: -240,
  },
  {
    id: "59",
    countryCode: "BA",
    countryName: "Bosnia and Herzegovina",
    name: "Europe/Sarajevo",
    utcOffset: 120,
  },
  {
    id: "60",
    countryCode: "BW",
    countryName: "Botswana",
    name: "Africa/Gaborone",
    utcOffset: 120,
  },
  {
    id: "61",
    countryCode: "BR",
    countryName: "Brazil",
    name: "America/Araguaina",
    utcOffset: -180,
  },
  {
    id: "62",
    countryCode: "BR",
    countryName: "Brazil",
    name: "America/Bahia",
    utcOffset: -180,
  },
  {
    id: "63",
    countryCode: "BR",
    countryName: "Brazil",
    name: "America/Belem",
    utcOffset: -180,
  },
  {
    id: "64",
    countryCode: "BR",
    countryName: "Brazil",
    name: "America/Boa_Vista",
    utcOffset: -240,
  },
  {
    id: "65",
    countryCode: "BR",
    countryName: "Brazil",
    name: "America/Campo_Grande",
    utcOffset: -240,
  },
  {
    id: "66",
    countryCode: "BR",
    countryName: "Brazil",
    name: "America/Cuiaba",
    utcOffset: -240,
  },
  {
    id: "67",
    countryCode: "BR",
    countryName: "Brazil",
    name: "America/Eirunepe",
    utcOffset: -300,
  },
  {
    id: "68",
    countryCode: "BR",
    countryName: "Brazil",
    name: "America/Fortaleza",
    utcOffset: -180,
  },
  {
    id: "69",
    countryCode: "BR",
    countryName: "Brazil",
    name: "America/Maceio",
    utcOffset: -180,
  },
  {
    id: "70",
    countryCode: "BR",
    countryName: "Brazil",
    name: "America/Manaus",
    utcOffset: -240,
  },
  {
    id: "71",
    countryCode: "BR",
    countryName: "Brazil",
    name: "America/Noronha",
    utcOffset: -120,
  },
  {
    id: "72",
    countryCode: "BR",
    countryName: "Brazil",
    name: "America/Porto_Velho",
    utcOffset: -240,
  },
  {
    id: "73",
    countryCode: "BR",
    countryName: "Brazil",
    name: "America/Recife",
    utcOffset: -180,
  },
  {
    id: "74",
    countryCode: "BR",
    countryName: "Brazil",
    name: "America/Rio_Branco",
    utcOffset: -300,
  },
  {
    id: "75",
    countryCode: "BR",
    countryName: "Brazil",
    name: "America/Santarem",
    utcOffset: -180,
  },
  {
    id: "76",
    countryCode: "BR",
    countryName: "Brazil",
    name: "America/Sao_Paulo",
    utcOffset: -180,
  },
  {
    id: "77",
    countryCode: "IO",
    countryName: "British Indian Ocean Territory",
    name: "Indian/Chagos",
    utcOffset: 360,
  },
  {
    id: "78",
    countryCode: "BN",
    countryName: "Brunei Darussalam",
    name: "Asia/Brunei",
    utcOffset: 480,
  },
  {
    id: "79",
    countryCode: "BG",
    countryName: "Bulgaria",
    name: "Europe/Sofia",
    utcOffset: 180,
  },
  {
    id: "80",
    countryCode: "BF",
    countryName: "Burkina Faso",
    name: "Africa/Ouagadougou",
    utcOffset: 0,
  },
  {
    id: "81",
    countryCode: "BI",
    countryName: "Burundi",
    name: "Africa/Bujumbura",
    utcOffset: 120,
  },
  {
    id: "82",
    countryCode: "KH",
    countryName: "Cambodia",
    name: "Asia/Phnom_Penh",
    utcOffset: 420,
  },
  {
    id: "83",
    countryCode: "CM",
    countryName: "Cameroon",
    name: "Africa/Douala",
    utcOffset: 60,
  },
  {
    id: "84",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Atikokan",
    utcOffset: -300,
  },
  {
    id: "85",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Blanc-Sablon",
    utcOffset: -240,
  },
  {
    id: "86",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Cambridge_Bay",
    utcOffset: -360,
  },
  {
    id: "87",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Creston",
    utcOffset: -420,
  },
  {
    id: "88",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Dawson",
    utcOffset: -420,
  },
  {
    id: "89",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Dawson_Creek",
    utcOffset: -420,
  },
  {
    id: "90",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Edmonton",
    utcOffset: -360,
  },
  {
    id: "91",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Fort_Nelson",
    utcOffset: -420,
  },
  {
    id: "92",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Glace_Bay",
    utcOffset: -180,
  },
  {
    id: "93",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Goose_Bay",
    utcOffset: -180,
  },
  {
    id: "94",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Halifax",
    utcOffset: -180,
  },
  {
    id: "95",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Inuvik",
    utcOffset: -360,
  },
  {
    id: "96",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Iqaluit",
    utcOffset: -240,
  },
  {
    id: "97",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Moncton",
    utcOffset: -180,
  },
  {
    id: "98",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Rankin_Inlet",
    utcOffset: -300,
  },
  {
    id: "99",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Regina",
    utcOffset: -360,
  },
  {
    id: "100",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Resolute",
    utcOffset: -300,
  },
  {
    id: "101",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/St_Johns",
    utcOffset: -150,
  },
  {
    id: "102",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Swift_Current",
    utcOffset: -360,
  },
  {
    id: "103",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Toronto",
    utcOffset: -240,
  },
  {
    id: "104",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Vancouver",
    utcOffset: -420,
  },
  {
    id: "105",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Whitehorse",
    utcOffset: -420,
  },
  {
    id: "106",
    countryCode: "CA",
    countryName: "Canada",
    name: "America/Winnipeg",
    utcOffset: -300,
  },
  {
    id: "107",
    countryCode: "CV",
    countryName: "Cape Verde",
    name: "Atlantic/Cape_Verde",
    utcOffset: -60,
  },
  {
    id: "108",
    countryCode: "KY",
    countryName: "Cayman Islands",
    name: "America/Cayman",
    utcOffset: -300,
  },
  {
    id: "109",
    countryCode: "CF",
    countryName: "Central African Republic",
    name: "Africa/Bangui",
    utcOffset: 60,
  },
  {
    id: "110",
    countryCode: "TD",
    countryName: "Chad",
    name: "Africa/Ndjamena",
    utcOffset: 60,
  },
  {
    id: "111",
    countryCode: "CL",
    countryName: "Chile",
    name: "America/Punta_Arenas",
    utcOffset: -180,
  },
  {
    id: "112",
    countryCode: "CL",
    countryName: "Chile",
    name: "America/Santiago",
    utcOffset: -240,
  },
  {
    id: "113",
    countryCode: "CL",
    countryName: "Chile",
    name: "Pacific/Easter",
    utcOffset: -360,
  },
  {
    id: "114",
    countryCode: "CN",
    countryName: "China",
    name: "Asia/Shanghai",
    utcOffset: 480,
  },
  {
    id: "115",
    countryCode: "CN",
    countryName: "China",
    name: "Asia/Urumqi",
    utcOffset: 360,
  },
  {
    id: "116",
    countryCode: "CX",
    countryName: "Christmas Island",
    name: "Indian/Christmas",
    utcOffset: 420,
  },
  {
    id: "117",
    countryCode: "CC",
    countryName: "Cocos (Keeling) Islands",
    name: "Indian/Cocos",
    utcOffset: 390,
  },
  {
    id: "118",
    countryCode: "CO",
    countryName: "Colombia",
    name: "America/Bogota",
    utcOffset: -300,
  },
  {
    id: "119",
    countryCode: "KM",
    countryName: "Comoros",
    name: "Indian/Comoro",
    utcOffset: 180,
  },
  {
    id: "120",
    countryCode: "CG",
    countryName: "Congo",
    name: "Africa/Brazzaville",
    utcOffset: 60,
  },
  {
    id: "121",
    countryCode: "CD",
    countryName: "Congo, the Democratic Republic of the",
    name: "Africa/Kinshasa",
    utcOffset: 60,
  },
  {
    id: "122",
    countryCode: "CD",
    countryName: "Congo, the Democratic Republic of the",
    name: "Africa/Lubumbashi",
    utcOffset: 120,
  },
  {
    id: "123",
    countryCode: "CK",
    countryName: "Cook Islands",
    name: "Pacific/Rarotonga",
    utcOffset: -600,
  },
  {
    id: "124",
    countryCode: "CR",
    countryName: "Costa Rica",
    name: "America/Costa_Rica",
    utcOffset: -360,
  },
  {
    id: "125",
    countryCode: "HR",
    countryName: "Croatia",
    name: "Europe/Zagreb",
    utcOffset: 120,
  },
  {
    id: "126",
    countryCode: "CU",
    countryName: "Cuba",
    name: "America/Havana",
    utcOffset: -240,
  },
  {
    id: "127",
    countryCode: "CW",
    countryName: "Curaçao",
    name: "America/Curacao",
    utcOffset: -240,
  },
  {
    id: "128",
    countryCode: "CY",
    countryName: "Cyprus",
    name: "Asia/Famagusta",
    utcOffset: 180,
  },
  {
    id: "129",
    countryCode: "CY",
    countryName: "Cyprus",
    name: "Asia/Nicosia",
    utcOffset: 180,
  },
  {
    id: "130",
    countryCode: "CZ",
    countryName: "Czech Republic",
    name: "Europe/Prague",
    utcOffset: 120,
  },
  {
    id: "131",
    countryCode: "CI",
    countryName: "Côte d'Ivoire",
    name: "Africa/Abidjan",
    utcOffset: 0,
  },
  {
    id: "132",
    countryCode: "DK",
    countryName: "Denmark",
    name: "Europe/Copenhagen",
    utcOffset: 120,
  },
  {
    id: "133",
    countryCode: "DJ",
    countryName: "Djibouti",
    name: "Africa/Djibouti",
    utcOffset: 180,
  },
  {
    id: "134",
    countryCode: "DM",
    countryName: "Dominica",
    name: "America/Dominica",
    utcOffset: -240,
  },
  {
    id: "135",
    countryCode: "DO",
    countryName: "Dominican Republic",
    name: "America/Santo_Domingo",
    utcOffset: -240,
  },
  {
    id: "136",
    countryCode: "EC",
    countryName: "Ecuador",
    name: "America/Guayaquil",
    utcOffset: -300,
  },
  {
    id: "137",
    countryCode: "EC",
    countryName: "Ecuador",
    name: "Pacific/Galapagos",
    utcOffset: -360,
  },
  {
    id: "138",
    countryCode: "EG",
    countryName: "Egypt",
    name: "Africa/Cairo",
    utcOffset: 180,
  },
  {
    id: "139",
    countryCode: "SV",
    countryName: "El Salvador",
    name: "America/El_Salvador",
    utcOffset: -360,
  },
  {
    id: "140",
    countryCode: "GQ",
    countryName: "Equatorial Guinea",
    name: "Africa/Malabo",
    utcOffset: 60,
  },
  {
    id: "141",
    countryCode: "ER",
    countryName: "Eritrea",
    name: "Africa/Asmara",
    utcOffset: 180,
  },
  {
    id: "142",
    countryCode: "EE",
    countryName: "Estonia",
    name: "Europe/Tallinn",
    utcOffset: 180,
  },
  {
    id: "143",
    countryCode: "ET",
    countryName: "Ethiopia",
    name: "Africa/Addis_Ababa",
    utcOffset: 180,
  },
  {
    id: "144",
    countryCode: "FK",
    countryName: "Falkland Islands (Malvinas)",
    name: "Atlantic/Stanley",
    utcOffset: -180,
  },
  {
    id: "145",
    countryCode: "FO",
    countryName: "Faroe Islands",
    name: "Atlantic/Faroe",
    utcOffset: 60,
  },
  {
    id: "146",
    countryCode: "FJ",
    countryName: "Fiji",
    name: "Pacific/Fiji",
    utcOffset: 720,
  },
  {
    id: "147",
    countryCode: "FI",
    countryName: "Finland",
    name: "Europe/Helsinki",
    utcOffset: 180,
  },
  {
    id: "148",
    countryCode: "FR",
    countryName: "France",
    name: "Europe/Paris",
    utcOffset: 120,
  },
  {
    id: "149",
    countryCode: "GF",
    countryName: "French Guiana",
    name: "America/Cayenne",
    utcOffset: -180,
  },
  {
    id: "150",
    countryCode: "PF",
    countryName: "French Polynesia",
    name: "Pacific/Gambier",
    utcOffset: -540,
  },
  {
    id: "151",
    countryCode: "PF",
    countryName: "French Polynesia",
    name: "Pacific/Marquesas",
    utcOffset: -570,
  },
  {
    id: "152",
    countryCode: "PF",
    countryName: "French Polynesia",
    name: "Pacific/Tahiti",
    utcOffset: -600,
  },
  {
    id: "153",
    countryCode: "TF",
    countryName: "French Southern Territories",
    name: "Indian/Kerguelen",
    utcOffset: 300,
  },
  {
    id: "154",
    countryCode: "GA",
    countryName: "Gabon",
    name: "Africa/Libreville",
    utcOffset: 60,
  },
  {
    id: "155",
    countryCode: "GM",
    countryName: "Gambia",
    name: "Africa/Banjul",
    utcOffset: 0,
  },
  {
    id: "156",
    countryCode: "GE",
    countryName: "Georgia",
    name: "Asia/Tbilisi",
    utcOffset: 240,
  },
  {
    id: "157",
    countryCode: "DE",
    countryName: "Germany",
    name: "Europe/Berlin",
    utcOffset: 120,
  },
  {
    id: "158",
    countryCode: "DE",
    countryName: "Germany",
    name: "Europe/Busingen",
    utcOffset: 120,
  },
  {
    id: "159",
    countryCode: "GH",
    countryName: "Ghana",
    name: "Africa/Accra",
    utcOffset: 0,
  },
  {
    id: "160",
    countryCode: "GI",
    countryName: "Gibraltar",
    name: "Europe/Gibraltar",
    utcOffset: 120,
  },
  {
    id: "161",
    countryCode: "GR",
    countryName: "Greece",
    name: "Europe/Athens",
    utcOffset: 180,
  },
  {
    id: "162",
    countryCode: "GL",
    countryName: "Greenland",
    name: "America/Danmarkshavn",
    utcOffset: 0,
  },
  {
    id: "163",
    countryCode: "GL",
    countryName: "Greenland",
    name: "America/Nuuk",
    utcOffset: -120,
  },
  {
    id: "164",
    countryCode: "GL",
    countryName: "Greenland",
    name: "America/Scoresbysund",
    utcOffset: 0,
  },
  {
    id: "165",
    countryCode: "GL",
    countryName: "Greenland",
    name: "America/Thule",
    utcOffset: -180,
  },
  {
    id: "166",
    countryCode: "GD",
    countryName: "Grenada",
    name: "America/Grenada",
    utcOffset: -240,
  },
  {
    id: "167",
    countryCode: "GP",
    countryName: "Guadeloupe",
    name: "America/Guadeloupe",
    utcOffset: -240,
  },
  {
    id: "168",
    countryCode: "GU",
    countryName: "Guam",
    name: "Pacific/Guam",
    utcOffset: 600,
  },
  {
    id: "169",
    countryCode: "GT",
    countryName: "Guatemala",
    name: "America/Guatemala",
    utcOffset: -360,
  },
  {
    id: "170",
    countryCode: "GG",
    countryName: "Guernsey",
    name: "Europe/Guernsey",
    utcOffset: 60,
  },
  {
    id: "171",
    countryCode: "GN",
    countryName: "Guinea",
    name: "Africa/Conakry",
    utcOffset: 0,
  },
  {
    id: "172",
    countryCode: "GW",
    countryName: "Guinea-Bissau",
    name: "Africa/Bissau",
    utcOffset: 0,
  },
  {
    id: "173",
    countryCode: "GY",
    countryName: "Guyana",
    name: "America/Guyana",
    utcOffset: -240,
  },
  {
    id: "174",
    countryCode: "HT",
    countryName: "Haiti",
    name: "America/Port-au-Prince",
    utcOffset: -240,
  },
  {
    id: "175",
    countryCode: "VA",
    countryName: "Holy See (Vatican City State)",
    name: "Europe/Vatican",
    utcOffset: 120,
  },
  {
    id: "176",
    countryCode: "HN",
    countryName: "Honduras",
    name: "America/Tegucigalpa",
    utcOffset: -360,
  },
  {
    id: "177",
    countryCode: "HK",
    countryName: "Hong Kong",
    name: "Asia/Hong_Kong",
    utcOffset: 480,
  },
  {
    id: "178",
    countryCode: "HU",
    countryName: "Hungary",
    name: "Europe/Budapest",
    utcOffset: 120,
  },
  {
    id: "179",
    countryCode: "IS",
    countryName: "Iceland",
    name: "Atlantic/Reykjavik",
    utcOffset: 0,
  },
  {
    id: "180",
    countryCode: "IN",
    countryName: "India",
    name: "Asia/Kolkata",
    utcOffset: 330,
  },
  {
    id: "181",
    countryCode: "ID",
    countryName: "Indonesia",
    name: "Asia/Jakarta",
    utcOffset: 420,
  },
  {
    id: "182",
    countryCode: "ID",
    countryName: "Indonesia",
    name: "Asia/Jayapura",
    utcOffset: 540,
  },
  {
    id: "183",
    countryCode: "ID",
    countryName: "Indonesia",
    name: "Asia/Makassar",
    utcOffset: 480,
  },
  {
    id: "184",
    countryCode: "ID",
    countryName: "Indonesia",
    name: "Asia/Pontianak",
    utcOffset: 420,
  },
  {
    id: "185",
    countryCode: "IR",
    countryName: "Iran, Islamic Republic of",
    name: "Asia/Tehran",
    utcOffset: 210,
  },
  {
    id: "186",
    countryCode: "IQ",
    countryName: "Iraq",
    name: "Asia/Baghdad",
    utcOffset: 180,
  },
  {
    id: "187",
    countryCode: "IE",
    countryName: "Ireland",
    name: "Europe/Dublin",
    utcOffset: 60,
  },
  {
    id: "188",
    countryCode: "IM",
    countryName: "Isle of Man",
    name: "Europe/Isle_of_Man",
    utcOffset: 60,
  },
  {
    id: "189",
    countryCode: "IL",
    countryName: "Israel",
    name: "Asia/Jerusalem",
    utcOffset: 180,
  },
  {
    id: "190",
    countryCode: "IT",
    countryName: "Italy",
    name: "Europe/Rome",
    utcOffset: 120,
  },
  {
    id: "191",
    countryCode: "JM",
    countryName: "Jamaica",
    name: "America/Jamaica",
    utcOffset: -300,
  },
  {
    id: "192",
    countryCode: "JP",
    countryName: "Japan",
    name: "Asia/Tokyo",
    utcOffset: 540,
  },
  {
    id: "193",
    countryCode: "JE",
    countryName: "Jersey",
    name: "Europe/Jersey",
    utcOffset: 60,
  },
  {
    id: "194",
    countryCode: "JO",
    countryName: "Jordan",
    name: "Asia/Amman",
    utcOffset: 180,
  },
  {
    id: "195",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    name: "Asia/Almaty",
    utcOffset: 360,
  },
  {
    id: "196",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    name: "Asia/Aqtau",
    utcOffset: 300,
  },
  {
    id: "197",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    name: "Asia/Aqtobe",
    utcOffset: 300,
  },
  {
    id: "198",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    name: "Asia/Atyrau",
    utcOffset: 300,
  },
  {
    id: "199",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    name: "Asia/Oral",
    utcOffset: 300,
  },
  {
    id: "200",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    name: "Asia/Qostanay",
    utcOffset: 360,
  },
  {
    id: "201",
    countryCode: "KZ",
    countryName: "Kazakhstan",
    name: "Asia/Qyzylorda",
    utcOffset: 300,
  },
  {
    id: "202",
    countryCode: "KE",
    countryName: "Kenya",
    name: "Africa/Nairobi",
    utcOffset: 180,
  },
  {
    id: "203",
    countryCode: "KI",
    countryName: "Kiribati",
    name: "Pacific/Kanton",
    utcOffset: 780,
  },
  {
    id: "204",
    countryCode: "KI",
    countryName: "Kiribati",
    name: "Pacific/Kiritimati",
    utcOffset: 840,
  },
  {
    id: "205",
    countryCode: "KI",
    countryName: "Kiribati",
    name: "Pacific/Tarawa",
    utcOffset: 720,
  },
  {
    id: "206",
    countryCode: "KP",
    countryName: "Korea, Democratic People's Republic of",
    name: "Asia/Pyongyang",
    utcOffset: 540,
  },
  {
    id: "207",
    countryCode: "KR",
    countryName: "Korea, Republic of",
    name: "Asia/Seoul",
    utcOffset: 540,
  },
  {
    id: "208",
    countryCode: "KW",
    countryName: "Kuwait",
    name: "Asia/Kuwait",
    utcOffset: 180,
  },
  {
    id: "209",
    countryCode: "KG",
    countryName: "Kyrgyzstan",
    name: "Asia/Bishkek",
    utcOffset: 360,
  },
  {
    id: "210",
    countryCode: "LA",
    countryName: "Lao People's Democratic Republic",
    name: "Asia/Vientiane",
    utcOffset: 420,
  },
  {
    id: "211",
    countryCode: "LV",
    countryName: "Latvia",
    name: "Europe/Riga",
    utcOffset: 180,
  },
  {
    id: "212",
    countryCode: "LB",
    countryName: "Lebanon",
    name: "Asia/Beirut",
    utcOffset: 180,
  },
  {
    id: "213",
    countryCode: "LS",
    countryName: "Lesotho",
    name: "Africa/Maseru",
    utcOffset: 120,
  },
  {
    id: "214",
    countryCode: "LR",
    countryName: "Liberia",
    name: "Africa/Monrovia",
    utcOffset: 0,
  },
  {
    id: "215",
    countryCode: "LY",
    countryName: "Libya",
    name: "Africa/Tripoli",
    utcOffset: 120,
  },
  {
    id: "216",
    countryCode: "LI",
    countryName: "Liechtenstein",
    name: "Europe/Vaduz",
    utcOffset: 120,
  },
  {
    id: "217",
    countryCode: "LT",
    countryName: "Lithuania",
    name: "Europe/Vilnius",
    utcOffset: 180,
  },
  {
    id: "218",
    countryCode: "LU",
    countryName: "Luxembourg",
    name: "Europe/Luxembourg",
    utcOffset: 120,
  },
  {
    id: "219",
    countryCode: "MO",
    countryName: "Macao",
    name: "Asia/Macau",
    utcOffset: 480,
  },
  {
    id: "220",
    countryCode: "MK",
    countryName: "Macedonia, the Former Yugoslav Republic of",
    name: "Europe/Skopje",
    utcOffset: 120,
  },
  {
    id: "221",
    countryCode: "MG",
    countryName: "Madagascar",
    name: "Indian/Antananarivo",
    utcOffset: 180,
  },
  {
    id: "222",
    countryCode: "MW",
    countryName: "Malawi",
    name: "Africa/Blantyre",
    utcOffset: 120,
  },
  {
    id: "223",
    countryCode: "MY",
    countryName: "Malaysia",
    name: "Asia/Kuala_Lumpur",
    utcOffset: 480,
  },
  {
    id: "224",
    countryCode: "MY",
    countryName: "Malaysia",
    name: "Asia/Kuching",
    utcOffset: 480,
  },
  {
    id: "225",
    countryCode: "MV",
    countryName: "Maldives",
    name: "Indian/Maldives",
    utcOffset: 300,
  },
  {
    id: "226",
    countryCode: "ML",
    countryName: "Mali",
    name: "Africa/Bamako",
    utcOffset: 0,
  },
  {
    id: "227",
    countryCode: "MT",
    countryName: "Malta",
    name: "Europe/Malta",
    utcOffset: 120,
  },
  {
    id: "228",
    countryCode: "MH",
    countryName: "Marshall Islands",
    name: "Pacific/Kwajalein",
    utcOffset: 720,
  },
  {
    id: "229",
    countryCode: "MH",
    countryName: "Marshall Islands",
    name: "Pacific/Majuro",
    utcOffset: 720,
  },
  {
    id: "230",
    countryCode: "MQ",
    countryName: "Martinique",
    name: "America/Martinique",
    utcOffset: -240,
  },
  {
    id: "231",
    countryCode: "MR",
    countryName: "Mauritania",
    name: "Africa/Nouakchott",
    utcOffset: 0,
  },
  {
    id: "232",
    countryCode: "MU",
    countryName: "Mauritius",
    name: "Indian/Mauritius",
    utcOffset: 240,
  },
  {
    id: "233",
    countryCode: "YT",
    countryName: "Mayotte",
    name: "Indian/Mayotte",
    utcOffset: 180,
  },
  {
    id: "234",
    countryCode: "MX",
    countryName: "Mexico",
    name: "America/Bahia_Banderas",
    utcOffset: -360,
  },
  {
    id: "235",
    countryCode: "MX",
    countryName: "Mexico",
    name: "America/Cancun",
    utcOffset: -300,
  },
  {
    id: "236",
    countryCode: "MX",
    countryName: "Mexico",
    name: "America/Chihuahua",
    utcOffset: -360,
  },
  {
    id: "237",
    countryCode: "MX",
    countryName: "Mexico",
    name: "America/Ciudad_Juarez",
    utcOffset: -360,
  },
  {
    id: "238",
    countryCode: "MX",
    countryName: "Mexico",
    name: "America/Hermosillo",
    utcOffset: -420,
  },
  {
    id: "239",
    countryCode: "MX",
    countryName: "Mexico",
    name: "America/Matamoros",
    utcOffset: -300,
  },
  {
    id: "240",
    countryCode: "MX",
    countryName: "Mexico",
    name: "America/Mazatlan",
    utcOffset: -420,
  },
  {
    id: "241",
    countryCode: "MX",
    countryName: "Mexico",
    name: "America/Merida",
    utcOffset: -360,
  },
  {
    id: "242",
    countryCode: "MX",
    countryName: "Mexico",
    name: "America/Mexico_City",
    utcOffset: -360,
  },
  {
    id: "243",
    countryCode: "MX",
    countryName: "Mexico",
    name: "America/Monterrey",
    utcOffset: -360,
  },
  {
    id: "244",
    countryCode: "MX",
    countryName: "Mexico",
    name: "America/Ojinaga",
    utcOffset: -300,
  },
  {
    id: "245",
    countryCode: "MX",
    countryName: "Mexico",
    name: "America/Tijuana",
    utcOffset: -420,
  },
  {
    id: "246",
    countryCode: "FM",
    countryName: "Micronesia, Federated States of",
    name: "Pacific/Chuuk",
    utcOffset: 600,
  },
  {
    id: "247",
    countryCode: "FM",
    countryName: "Micronesia, Federated States of",
    name: "Pacific/Kosrae",
    utcOffset: 660,
  },
  {
    id: "248",
    countryCode: "FM",
    countryName: "Micronesia, Federated States of",
    name: "Pacific/Pohnpei",
    utcOffset: 660,
  },
  {
    id: "249",
    countryCode: "MD",
    countryName: "Moldova, Republic of",
    name: "Europe/Chisinau",
    utcOffset: 180,
  },
  {
    id: "250",
    countryCode: "MC",
    countryName: "Monaco",
    name: "Europe/Monaco",
    utcOffset: 120,
  },
  {
    id: "251",
    countryCode: "MN",
    countryName: "Mongolia",
    name: "Asia/Choibalsan",
    utcOffset: 480,
  },
  {
    id: "252",
    countryCode: "MN",
    countryName: "Mongolia",
    name: "Asia/Hovd",
    utcOffset: 420,
  },
  {
    id: "253",
    countryCode: "MN",
    countryName: "Mongolia",
    name: "Asia/Ulaanbaatar",
    utcOffset: 480,
  },
  {
    id: "254",
    countryCode: "ME",
    countryName: "Montenegro",
    name: "Europe/Podgorica",
    utcOffset: 120,
  },
  {
    id: "255",
    countryCode: "MS",
    countryName: "Montserrat",
    name: "America/Montserrat",
    utcOffset: -240,
  },
  {
    id: "256",
    countryCode: "MA",
    countryName: "Morocco",
    name: "Africa/Casablanca",
    utcOffset: 60,
  },
  {
    id: "257",
    countryCode: "MA",
    countryName: "Morocco",
    name: "Africa/El_Aaiun",
    utcOffset: 60,
  },
  {
    id: "258",
    countryCode: "MZ",
    countryName: "Mozambique",
    name: "Africa/Maputo",
    utcOffset: 120,
  },
  {
    id: "259",
    countryCode: "MM",
    countryName: "Myanmar",
    name: "Asia/Yangon",
    utcOffset: 390,
  },
  {
    id: "260",
    countryCode: "NA",
    countryName: "Namibia",
    name: "Africa/Windhoek",
    utcOffset: 120,
  },
  {
    id: "261",
    countryCode: "NR",
    countryName: "Nauru",
    name: "Pacific/Nauru",
    utcOffset: 720,
  },
  {
    id: "262",
    countryCode: "NP",
    countryName: "Nepal",
    name: "Asia/Kathmandu",
    utcOffset: 345,
  },
  {
    id: "263",
    countryCode: "NL",
    countryName: "Netherlands",
    name: "Europe/Amsterdam",
    utcOffset: 120,
  },
  {
    id: "264",
    countryCode: "NC",
    countryName: "New Caledonia",
    name: "Pacific/Noumea",
    utcOffset: 660,
  },
  {
    id: "265",
    countryCode: "NZ",
    countryName: "New Zealand",
    name: "Pacific/Auckland",
    utcOffset: 720,
  },
  {
    id: "266",
    countryCode: "NZ",
    countryName: "New Zealand",
    name: "Pacific/Chatham",
    utcOffset: 765,
  },
  {
    id: "267",
    countryCode: "NI",
    countryName: "Nicaragua",
    name: "America/Managua",
    utcOffset: -360,
  },
  {
    id: "268",
    countryCode: "NE",
    countryName: "Niger",
    name: "Africa/Niamey",
    utcOffset: 60,
  },
  {
    id: "269",
    countryCode: "NG",
    countryName: "Nigeria",
    name: "Africa/Lagos",
    utcOffset: 60,
  },
  {
    id: "270",
    countryCode: "NU",
    countryName: "Niue",
    name: "Pacific/Niue",
    utcOffset: -660,
  },
  {
    id: "271",
    countryCode: "NF",
    countryName: "Norfolk Island",
    name: "Pacific/Norfolk",
    utcOffset: 660,
  },
  {
    id: "272",
    countryCode: "MP",
    countryName: "Northern Mariana Islands",
    name: "Pacific/Saipan",
    utcOffset: 600,
  },
  {
    id: "273",
    countryCode: "NO",
    countryName: "Norway",
    name: "Europe/Oslo",
    utcOffset: 120,
  },
  {
    id: "274",
    countryCode: "OM",
    countryName: "Oman",
    name: "Asia/Muscat",
    utcOffset: 240,
  },
  {
    id: "275",
    countryCode: "PK",
    countryName: "Pakistan",
    name: "Asia/Karachi",
    utcOffset: 300,
  },
  {
    id: "276",
    countryCode: "PW",
    countryName: "Palau",
    name: "Pacific/Palau",
    utcOffset: 540,
  },
  {
    id: "277",
    countryCode: "PS",
    countryName: "Palestine, State of",
    name: "Asia/Gaza",
    utcOffset: 180,
  },
  {
    id: "278",
    countryCode: "PS",
    countryName: "Palestine, State of",
    name: "Asia/Hebron",
    utcOffset: 180,
  },
  {
    id: "279",
    countryCode: "PA",
    countryName: "Panama",
    name: "America/Panama",
    utcOffset: -300,
  },
  {
    id: "280",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    name: "Pacific/Bougainville",
    utcOffset: 660,
  },
  {
    id: "281",
    countryCode: "PG",
    countryName: "Papua New Guinea",
    name: "Pacific/Port_Moresby",
    utcOffset: 600,
  },
  {
    id: "282",
    countryCode: "PY",
    countryName: "Paraguay",
    name: "America/Asuncion",
    utcOffset: -240,
  },
  {
    id: "283",
    countryCode: "PE",
    countryName: "Peru",
    name: "America/Lima",
    utcOffset: -300,
  },
  {
    id: "284",
    countryCode: "PH",
    countryName: "Philippines",
    name: "Asia/Manila",
    utcOffset: 480,
  },
  {
    id: "285",
    countryCode: "PN",
    countryName: "Pitcairn",
    name: "Pacific/Pitcairn",
    utcOffset: -480,
  },
  {
    id: "286",
    countryCode: "PL",
    countryName: "Poland",
    name: "Europe/Warsaw",
    utcOffset: 120,
  },
  {
    id: "287",
    countryCode: "PT",
    countryName: "Portugal",
    name: "Atlantic/Azores",
    utcOffset: 0,
  },
  {
    id: "288",
    countryCode: "PT",
    countryName: "Portugal",
    name: "Atlantic/Madeira",
    utcOffset: 60,
  },
  {
    id: "289",
    countryCode: "PT",
    countryName: "Portugal",
    name: "Europe/Lisbon",
    utcOffset: 60,
  },
  {
    id: "290",
    countryCode: "PR",
    countryName: "Puerto Rico",
    name: "America/Puerto_Rico",
    utcOffset: -240,
  },
  {
    id: "291",
    countryCode: "QA",
    countryName: "Qatar",
    name: "Asia/Qatar",
    utcOffset: 180,
  },
  {
    id: "292",
    countryCode: "RO",
    countryName: "Romania",
    name: "Europe/Bucharest",
    utcOffset: 180,
  },
  {
    id: "293",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Asia/Anadyr",
    utcOffset: 720,
  },
  {
    id: "294",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Asia/Barnaul",
    utcOffset: 420,
  },
  {
    id: "295",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Asia/Chita",
    utcOffset: 540,
  },
  {
    id: "296",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Asia/Irkutsk",
    utcOffset: 480,
  },
  {
    id: "297",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Asia/Kamchatka",
    utcOffset: 720,
  },
  {
    id: "298",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Asia/Khandyga",
    utcOffset: 540,
  },
  {
    id: "299",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Asia/Krasnoyarsk",
    utcOffset: 420,
  },
  {
    id: "300",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Asia/Magadan",
    utcOffset: 660,
  },
  {
    id: "301",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Asia/Novokuznetsk",
    utcOffset: 420,
  },
  {
    id: "302",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Asia/Novosibirsk",
    utcOffset: 420,
  },
  {
    id: "303",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Asia/Omsk",
    utcOffset: 360,
  },
  {
    id: "304",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Asia/Sakhalin",
    utcOffset: 660,
  },
  {
    id: "305",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Asia/Srednekolymsk",
    utcOffset: 660,
  },
  {
    id: "306",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Asia/Tomsk",
    utcOffset: 420,
  },
  {
    id: "307",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Asia/Ust-Nera",
    utcOffset: 600,
  },
  {
    id: "308",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Asia/Vladivostok",
    utcOffset: 600,
  },
  {
    id: "309",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Asia/Yakutsk",
    utcOffset: 540,
  },
  {
    id: "310",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Asia/Yekaterinburg",
    utcOffset: 300,
  },
  {
    id: "311",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Europe/Astrakhan",
    utcOffset: 240,
  },
  {
    id: "312",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Europe/Kaliningrad",
    utcOffset: 120,
  },
  {
    id: "313",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Europe/Kirov",
    utcOffset: 180,
  },
  {
    id: "314",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Europe/Moscow",
    utcOffset: 180,
  },
  {
    id: "315",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Europe/Samara",
    utcOffset: 240,
  },
  {
    id: "316",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Europe/Saratov",
    utcOffset: 240,
  },
  {
    id: "317",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Europe/Ulyanovsk",
    utcOffset: 240,
  },
  {
    id: "318",
    countryCode: "RU",
    countryName: "Russian Federation",
    name: "Europe/Volgograd",
    utcOffset: 180,
  },
  {
    id: "319",
    countryCode: "RW",
    countryName: "Rwanda",
    name: "Africa/Kigali",
    utcOffset: 120,
  },
  {
    id: "320",
    countryCode: "RE",
    countryName: "Réunion",
    name: "Indian/Reunion",
    utcOffset: 240,
  },
  {
    id: "321",
    countryCode: "BL",
    countryName: "Saint Barthélemy",
    name: "America/St_Barthelemy",
    utcOffset: -240,
  },
  {
    id: "322",
    countryCode: "SH",
    countryName: "Saint Helena, Ascension and Tristan da Cunha",
    name: "Atlantic/St_Helena",
    utcOffset: 0,
  },
  {
    id: "323",
    countryCode: "KN",
    countryName: "Saint Kitts and Nevis",
    name: "America/St_Kitts",
    utcOffset: -240,
  },
  {
    id: "324",
    countryCode: "LC",
    countryName: "Saint Lucia",
    name: "America/St_Lucia",
    utcOffset: -240,
  },
  {
    id: "325",
    countryCode: "MF",
    countryName: "Saint Martin (French part)",
    name: "America/Marigot",
    utcOffset: -240,
  },
  {
    id: "326",
    countryCode: "PM",
    countryName: "Saint Pierre and Miquelon",
    name: "America/Miquelon",
    utcOffset: -120,
  },
  {
    id: "327",
    countryCode: "VC",
    countryName: "Saint Vincent and the Grenadines",
    name: "America/St_Vincent",
    utcOffset: -240,
  },
  {
    id: "328",
    countryCode: "WS",
    countryName: "Samoa",
    name: "Pacific/Apia",
    utcOffset: 780,
  },
  {
    id: "329",
    countryCode: "SM",
    countryName: "San Marino",
    name: "Europe/San_Marino",
    utcOffset: 120,
  },
  {
    id: "330",
    countryCode: "ST",
    countryName: "Sao Tome and Principe",
    name: "Africa/Sao_Tome",
    utcOffset: 0,
  },
  {
    id: "331",
    countryCode: "SA",
    countryName: "Saudi Arabia",
    name: "Asia/Riyadh",
    utcOffset: 180,
  },
  {
    id: "332",
    countryCode: "SN",
    countryName: "Senegal",
    name: "Africa/Dakar",
    utcOffset: 0,
  },
  {
    id: "333",
    countryCode: "RS",
    countryName: "Serbia",
    name: "Europe/Belgrade",
    utcOffset: 120,
  },
  {
    id: "334",
    countryCode: "SC",
    countryName: "Seychelles",
    name: "Indian/Mahe",
    utcOffset: 240,
  },
  {
    id: "335",
    countryCode: "SL",
    countryName: "Sierra Leone",
    name: "Africa/Freetown",
    utcOffset: 0,
  },
  {
    id: "336",
    countryCode: "SG",
    countryName: "Singapore",
    name: "Asia/Singapore",
    utcOffset: 480,
  },
  {
    id: "337",
    countryCode: "SX",
    countryName: "Sint Maarten (Dutch part)",
    name: "America/Lower_Princes",
    utcOffset: -240,
  },
  {
    id: "338",
    countryCode: "SK",
    countryName: "Slovakia",
    name: "Europe/Bratislava",
    utcOffset: 120,
  },
  {
    id: "339",
    countryCode: "SI",
    countryName: "Slovenia",
    name: "Europe/Ljubljana",
    utcOffset: 120,
  },
  {
    id: "340",
    countryCode: "SB",
    countryName: "Solomon Islands",
    name: "Pacific/Guadalcanal",
    utcOffset: 660,
  },
  {
    id: "341",
    countryCode: "SO",
    countryName: "Somalia",
    name: "Africa/Mogadishu",
    utcOffset: 180,
  },
  {
    id: "342",
    countryCode: "ZA",
    countryName: "South Africa",
    name: "Africa/Johannesburg",
    utcOffset: 120,
  },
  {
    id: "343",
    countryCode: "GS",
    countryName: "South Georgia and the South Sandwich Islands",
    name: "Atlantic/South_Georgia",
    utcOffset: -120,
  },
  {
    id: "344",
    countryCode: "SS",
    countryName: "South Sudan",
    name: "Africa/Juba",
    utcOffset: 120,
  },
  {
    id: "345",
    countryCode: "ES",
    countryName: "Spain",
    name: "Africa/Ceuta",
    utcOffset: 120,
  },
  {
    id: "346",
    countryCode: "ES",
    countryName: "Spain",
    name: "Atlantic/Canary",
    utcOffset: 60,
  },
  {
    id: "347",
    countryCode: "ES",
    countryName: "Spain",
    name: "Europe/Madrid",
    utcOffset: 120,
  },
  {
    id: "348",
    countryCode: "LK",
    countryName: "Sri Lanka",
    name: "Asia/Colombo",
    utcOffset: 330,
  },
  {
    id: "349",
    countryCode: "SD",
    countryName: "Sudan",
    name: "Africa/Khartoum",
    utcOffset: 120,
  },
  {
    id: "350",
    countryCode: "SR",
    countryName: "Suriname",
    name: "America/Paramaribo",
    utcOffset: -180,
  },
  {
    id: "351",
    countryCode: "SJ",
    countryName: "Svalbard and Jan Mayen",
    name: "Arctic/Longyearbyen",
    utcOffset: 120,
  },
  {
    id: "352",
    countryCode: "SZ",
    countryName: "Swaziland",
    name: "Africa/Mbabane",
    utcOffset: 120,
  },
  {
    id: "353",
    countryCode: "SE",
    countryName: "Sweden",
    name: "Europe/Stockholm",
    utcOffset: 120,
  },
  {
    id: "354",
    countryCode: "CH",
    countryName: "Switzerland",
    name: "Europe/Zurich",
    utcOffset: 120,
  },
  {
    id: "355",
    countryCode: "SY",
    countryName: "Syrian Arab Republic",
    name: "Asia/Damascus",
    utcOffset: 180,
  },
  {
    id: "356",
    countryCode: "TW",
    countryName: "Taiwan, Province of China",
    name: "Asia/Taipei",
    utcOffset: 480,
  },
  {
    id: "357",
    countryCode: "TJ",
    countryName: "Tajikistan",
    name: "Asia/Dushanbe",
    utcOffset: 300,
  },
  {
    id: "358",
    countryCode: "TZ",
    countryName: "Tanzania, United Republic of",
    name: "Africa/Dar_es_Salaam",
    utcOffset: 180,
  },
  {
    id: "359",
    countryCode: "TH",
    countryName: "Thailand",
    name: "Asia/Bangkok",
    utcOffset: 420,
  },
  {
    id: "360",
    countryCode: "TL",
    countryName: "Timor-Leste",
    name: "Asia/Dili",
    utcOffset: 540,
  },
  {
    id: "361",
    countryCode: "TG",
    countryName: "Togo",
    name: "Africa/Lome",
    utcOffset: 0,
  },
  {
    id: "362",
    countryCode: "TK",
    countryName: "Tokelau",
    name: "Pacific/Fakaofo",
    utcOffset: 780,
  },
  {
    id: "363",
    countryCode: "TO",
    countryName: "Tonga",
    name: "Pacific/Tongatapu",
    utcOffset: 780,
  },
  {
    id: "364",
    countryCode: "TT",
    countryName: "Trinidad and Tobago",
    name: "America/Port_of_Spain",
    utcOffset: -240,
  },
  {
    id: "365",
    countryCode: "TN",
    countryName: "Tunisia",
    name: "Africa/Tunis",
    utcOffset: 60,
  },
  {
    id: "366",
    countryCode: "TR",
    countryName: "Turkey",
    name: "Europe/Istanbul",
    utcOffset: 180,
  },
  {
    id: "367",
    countryCode: "TM",
    countryName: "Turkmenistan",
    name: "Asia/Ashgabat",
    utcOffset: 300,
  },
  {
    id: "368",
    countryCode: "TC",
    countryName: "Turks and Caicos Islands",
    name: "America/Grand_Turk",
    utcOffset: -240,
  },
  {
    id: "369",
    countryCode: "TV",
    countryName: "Tuvalu",
    name: "Pacific/Funafuti",
    utcOffset: 720,
  },
  {
    id: "370",
    countryCode: "UG",
    countryName: "Uganda",
    name: "Africa/Kampala",
    utcOffset: 180,
  },
  {
    id: "371",
    countryCode: "UA",
    countryName: "Ukraine",
    name: "Europe/Kyiv",
    utcOffset: 180,
  },
  {
    id: "372",
    countryCode: "UA",
    countryName: "Ukraine",
    name: "Europe/Simferopol",
    utcOffset: 180,
  },
  {
    id: "373",
    countryCode: "AE",
    countryName: "United Arab Emirates",
    name: "Asia/Dubai",
    utcOffset: 240,
  },
  {
    id: "374",
    countryCode: "GB",
    countryName: "United Kingdom",
    name: "Europe/London",
    utcOffset: 60,
  },
  {
    id: "375",
    countryCode: "US",
    countryName: "United States",
    name: "America/Adak",
    utcOffset: -540,
  },
  {
    id: "376",
    countryCode: "US",
    countryName: "United States",
    name: "America/Anchorage",
    utcOffset: -480,
  },
  {
    id: "377",
    countryCode: "US",
    countryName: "United States",
    name: "America/Boise",
    utcOffset: -360,
  },
  {
    id: "378",
    countryCode: "US",
    countryName: "United States",
    name: "America/Chicago",
    utcOffset: -300,
  },
  {
    id: "379",
    countryCode: "US",
    countryName: "United States",
    name: "America/Denver",
    utcOffset: -360,
  },
  {
    id: "380",
    countryCode: "US",
    countryName: "United States",
    name: "America/Detroit",
    utcOffset: -240,
  },
  {
    id: "381",
    countryCode: "US",
    countryName: "United States",
    name: "America/Indiana/Indianapolis",
    utcOffset: -240,
  },
  {
    id: "382",
    countryCode: "US",
    countryName: "United States",
    name: "America/Indiana/Knox",
    utcOffset: -300,
  },
  {
    id: "383",
    countryCode: "US",
    countryName: "United States",
    name: "America/Indiana/Marengo",
    utcOffset: -240,
  },
  {
    id: "384",
    countryCode: "US",
    countryName: "United States",
    name: "America/Indiana/Petersburg",
    utcOffset: -240,
  },
  {
    id: "385",
    countryCode: "US",
    countryName: "United States",
    name: "America/Indiana/Tell_City",
    utcOffset: -300,
  },
  {
    id: "386",
    countryCode: "US",
    countryName: "United States",
    name: "America/Indiana/Vevay",
    utcOffset: -240,
  },
  {
    id: "387",
    countryCode: "US",
    countryName: "United States",
    name: "America/Indiana/Vincennes",
    utcOffset: -240,
  },
  {
    id: "388",
    countryCode: "US",
    countryName: "United States",
    name: "America/Indiana/Winamac",
    utcOffset: -240,
  },
  {
    id: "389",
    countryCode: "US",
    countryName: "United States",
    name: "America/Juneau",
    utcOffset: -480,
  },
  {
    id: "390",
    countryCode: "US",
    countryName: "United States",
    name: "America/Kentucky/Louisville",
    utcOffset: -240,
  },
  {
    id: "391",
    countryCode: "US",
    countryName: "United States",
    name: "America/Kentucky/Monticello",
    utcOffset: -240,
  },
  {
    id: "392",
    countryCode: "US",
    countryName: "United States",
    name: "America/Los_Angeles",
    utcOffset: -420,
  },
  {
    id: "393",
    countryCode: "US",
    countryName: "United States",
    name: "America/Menominee",
    utcOffset: -300,
  },
  {
    id: "394",
    countryCode: "US",
    countryName: "United States",
    name: "America/Metlakatla",
    utcOffset: -480,
  },
  {
    id: "395",
    countryCode: "US",
    countryName: "United States",
    name: "America/New_York",
    utcOffset: -240,
  },
  {
    id: "396",
    countryCode: "US",
    countryName: "United States",
    name: "America/Nome",
    utcOffset: -480,
  },
  {
    id: "397",
    countryCode: "US",
    countryName: "United States",
    name: "America/North_Dakota/Beulah",
    utcOffset: -300,
  },
  {
    id: "398",
    countryCode: "US",
    countryName: "United States",
    name: "America/North_Dakota/Center",
    utcOffset: -300,
  },
  {
    id: "399",
    countryCode: "US",
    countryName: "United States",
    name: "America/North_Dakota/New_Salem",
    utcOffset: -300,
  },
  {
    id: "400",
    countryCode: "US",
    countryName: "United States",
    name: "America/Phoenix",
    utcOffset: -420,
  },
  {
    id: "401",
    countryCode: "US",
    countryName: "United States",
    name: "America/Sitka",
    utcOffset: -480,
  },
  {
    id: "402",
    countryCode: "US",
    countryName: "United States",
    name: "America/Yakutat",
    utcOffset: -480,
  },
  {
    id: "403",
    countryCode: "US",
    countryName: "United States",
    name: "Pacific/Honolulu",
    utcOffset: -600,
  },
  {
    id: "404",
    countryCode: "UM",
    countryName: "United States Minor Outlying Islands",
    name: "Pacific/Midway",
    utcOffset: -660,
  },
  {
    id: "405",
    countryCode: "UM",
    countryName: "United States Minor Outlying Islands",
    name: "Pacific/Wake",
    utcOffset: 720,
  },
  {
    id: "406",
    countryCode: "UY",
    countryName: "Uruguay",
    name: "America/Montevideo",
    utcOffset: -180,
  },
  {
    id: "407",
    countryCode: "UZ",
    countryName: "Uzbekistan",
    name: "Asia/Samarkand",
    utcOffset: 300,
  },
  {
    id: "408",
    countryCode: "UZ",
    countryName: "Uzbekistan",
    name: "Asia/Tashkent",
    utcOffset: 300,
  },
  {
    id: "409",
    countryCode: "VU",
    countryName: "Vanuatu",
    name: "Pacific/Efate",
    utcOffset: 660,
  },
  {
    id: "410",
    countryCode: "VE",
    countryName: "Venezuela, Bolivarian Republic of",
    name: "America/Caracas",
    utcOffset: -240,
  },
  {
    id: "411",
    countryCode: "VN",
    countryName: "Viet Nam",
    name: "Asia/Ho_Chi_Minh",
    utcOffset: 420,
  },
  {
    id: "412",
    countryCode: "VG",
    countryName: "Virgin Islands, British",
    name: "America/Tortola",
    utcOffset: -240,
  },
  {
    id: "413",
    countryCode: "VI",
    countryName: "Virgin Islands, U.S.",
    name: "America/St_Thomas",
    utcOffset: -240,
  },
  {
    id: "414",
    countryCode: "WF",
    countryName: "Wallis and Futuna",
    name: "Pacific/Wallis",
    utcOffset: 720,
  },
  {
    id: "415",
    countryCode: "YE",
    countryName: "Yemen",
    name: "Asia/Aden",
    utcOffset: 180,
  },
  {
    id: "416",
    countryCode: "ZM",
    countryName: "Zambia",
    name: "Africa/Lusaka",
    utcOffset: 120,
  },
  {
    id: "417",
    countryCode: "ZW",
    countryName: "Zimbabwe",
    name: "Africa/Harare",
    utcOffset: 120,
  },
  {
    id: "418",
    countryCode: "AX",
    countryName: "Åland Islands",
    name: "Europe/Mariehamn",
    utcOffset: 180,
  },
];

export default tzs;
