import { Tz, TzTime } from "./models";

export class TzTimeClass {
  utcMinute: number = 0;
  tzs: Tz[] = [];

  constructor(tzs?: Tz[]) {
    if (tzs === undefined) return;
    this.tzs = tzs;
  }

  addTz(tz: Tz) {
    this.tzs.push(tz);
  }

  removeTz(i: number) {
    const x = [...this.tzs];
    x.splice(i, 1);
    this.tzs = x;
  }

  getTzDataList(): TzTime[] {
    return this.tzs.map((x, i) => {
      return {
        tz: x,
        minute: this.getTzMinute(i),
      };
    });
  }

  getTzMinute(i: number): number {
    if (this.tzs.length < i + 1) throw Error("this.tzs.length < i + 1");
    return this.utcMinute + this.tzs[i].utcOffset;
  }

  setTzMinute(i: number, minute: number) {
    if (this.tzs.length < i + 1) throw Error("this.tzs.length < i + 1");

    // 配列の最初のタイムゾーンの時刻は、0:00~24:00の間になるように設定する。
    if (i === 0) {
      this.utcMinute = this.minute0to24(minute) - this.tzs[i].utcOffset;
    } else {
      this.setTzMinute(0, minute - this.tzs[i].utcOffset + this.tzs[0].utcOffset);
    }
  }

  reorder(startIndex: number, endIndex: number) {
    const result = Array.from(this.tzs);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    this.tzs = result;
  }

  private minute0to24(minute: number) {
    let v = minute;
    while (v < 0) {
      v += 24 * 60;
    }
    while (v > 24 * 60) {
      v -= 24 * 60;
    }
    return v;
  }
}
