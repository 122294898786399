import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import tzs from "../models/tz";
import { Tz } from "../models/models";
import { utcOffsetText } from "../views/timeFormatter";

type Prop = {
  onSetTz: (tz: Tz) => void;
};

export default function TzSelect({ onSetTz }: Prop) {
  const [value, setValue] = React.useState<null>(null);

  return (
    <Autocomplete
      id="tz-select"
      sx={{ maxWidth: 500 }}
      options={tzs}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      autoHighlight
      getOptionLabel={(option) =>
        `${option.countryName}, ${option.name} (${utcOffsetText(option.utcOffset)})`
      }
      value={value}
      onChange={(_, value) => {
        if (value !== null) {
          onSetTz(value);
          setValue(null);
        }
      }}
      renderOption={(props, option) => (
        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.countryName}, {option.name} ({utcOffsetText(option.utcOffset)})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Add timezone"
          variant="standard"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
