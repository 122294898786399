/**
 *　時刻を分に変換
 * @param time hh:mm (e.g. 13:00)
 * @returns 分
 */
export function hhmm2minute(time: string): number {
  const [h, m] = time.split(":").map((x) => {
    return Number(x);
  });
  return h * 60 + m;
}

/**
 *　時刻を分に変換
 * @param time hmm (e.g. 900)
 * @returns 分
 */
export function hmm2minute(time: string): number {
  return Number(time.slice(0, -2)) * 60 + Number(time.slice(-2));
}

/**
 * 分を時刻に変換
 * @param minute 分
 * @param separator 区切り文字
 * @returns hh:mm (e.g. 13:00)
 */
export function minute2hhmm(minute: number, separator: string = ":"): string {
  const plusMinute = Math.abs(minute);
  const s = `${Math.floor(plusMinute / 60)}${separator}${(plusMinute % 60).toString().padStart(2, "0")}`;
  if (minute >= 0) {
    return s;
  } else {
    return `-${s}`;
  }
}

export function utcOffsetText(minute: number): string {
  if (minute === 0) return "UTC";
  if (minute >= 0) return `UTC+${minute2hhmm(minute)}`;
  return `UTC${minute2hhmm(minute)}`;
}
