import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import { TzTime } from "../models/models";
import { minute2hhmm, utcOffsetText } from "../views/timeFormatter";
import { IconButton, ListItemIcon, Slider, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DragDropContext, Droppable, Draggable, DropResult, DraggableProvided } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

type Prop = {
  tzTimes: TzTime[];
  setMinute: (i: number, minutes: number) => void;
  reorder: (startIndex: number, endIndex: number) => void;
  deleteTz: (i: number) => void;
};

export default function TzList({ tzTimes, setMinute, reorder, deleteTz }: Prop) {
  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // // some basic styles to make the items look a bit nicer
    // userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,
    // // change background colour if dragging
    // background: isDragging ? "lightgreen" : "grey",
    // // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: any) => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    // padding: grid,
    // width: 250,
  });

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    reorder(result.source.index, result.destination.index);
  };

  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {tzTimes.map((x, i) => (
                <Draggable key={i.toString()} draggableId={i.toString()} index={i}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <Item
                        tzTime={x}
                        setMinute={(minute) => {
                          setMinute(i, minute);
                        }}
                        deleteTz={() => {
                          deleteTz(i);
                        }}
                        provided={provided}
                      />
                      {i !== tzTimes.length - 1 && <Divider sx={{ mb: 2 }} variant="inset" component="li" />}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </List>
  );
}

const timeLabelRate = 60 * 2;

const marks = Array.from(Array((24 * 60) / timeLabelRate).keys()).map((x) => {
  return {
    value: x * timeLabelRate,
    label: minute2hhmm(x * timeLabelRate),
  };
});

type ItemProp = {
  tzTime: TzTime;
  setMinute: (minutes: number) => void;
  deleteTz: () => void;
  provided: DraggableProvided;
};

function Item({ tzTime, setMinute, deleteTz, provided }: ItemProp) {
  const handleMinuteChange = (event: Event, newValue: number | number[]) => {
    setMinute(newValue as number);
  };
  const handleDelete = () => {
    deleteTz();
  };

  const minuteWithDay = (value: number): string => {
    if (tzTime.minute >= 0 && tzTime.minute <= 24 * 60) {
      return minute2hhmm(value);
    } else if (tzTime.minute < 0) {
      return "-1 Day " + minute2hhmm(value);
    } else {
      return "+1 Day " + minute2hhmm(value);
    }
  };

  const minute0to24 = (minute: number): number => {
    let v = minute;
    while (v < 0) {
      v += 24 * 60;
    }
    while (v > 24 * 60) {
      v -= 24 * 60;
    }
    return v;
  };

  return (
    <React.Fragment>
      <ListItem
        alignItems="flex-start"
        secondaryAction={
          <IconButton edge="end" aria-label="delete" onClick={handleDelete} title="Delete">
            <DeleteIcon />
          </IconButton>
        }
      >
        <ListItemIcon {...provided.dragHandleProps}>
          <DragIndicatorIcon color="action" />
        </ListItemIcon>
        <ListItemIcon>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${tzTime.tz.countryCode.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${tzTime.tz.countryCode.toLowerCase()}.png 2x`}
            alt=""
          />
        </ListItemIcon>
        <ListItemText
          primary={tzTime.tz.countryName + ", " + tzTime.tz.name}
          secondary={
            <React.Fragment>
              <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
                ({utcOffsetText(tzTime.tz.utcOffset)})
              </Typography>
              <Slider
                aria-label="Time selector"
                getAriaValueText={(value: number) => minuteWithDay(value)}
                step={30}
                max={24 * 60}
                valueLabelDisplay="on"
                valueLabelFormat={(value: number) => minuteWithDay(value)}
                marks={marks}
                defaultValue={tzTime.minute}
                value={minute0to24(tzTime.minute)}
                onChange={handleMinuteChange}
              />
            </React.Fragment>
          }
        />
      </ListItem>
    </React.Fragment>
  );
}
