import { Box, Container, Typography } from "@mui/material";
import TzList from "../components/tzList";
import TzSelect from "../components/tzSelect";
import { Tz, TzTime } from "../models/models";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { queryToTzTimeClass, tzs2query } from "../utils/query";
import { TzTimeClass } from "../models/tzTime";
import { logAddTz, logDeleteTz, logReorderTz, logScreenView } from "../services/eventLogger";
import { findTzByName } from "../models/tz";
import { format } from "date-fns";
import { hhmm2minute } from "../views/timeFormatter";

let tzTime: TzTimeClass;

export default function MainLayout() {
  const [tzTimes, setTzTimes] = React.useState<TzTime[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const updateTzTimes = (): void => {
    setTzTimes([...tzTime.getTzDataList()]);
    setSearchParams(tzs2query(tzTime));
  };
  const addTz = (tz: Tz) => {
    logAddTz(tz);
    tzTime.addTz(tz);
    updateTzTimes();
  };
  const setMinute = (i: number, minute: number) => {
    tzTime.setTzMinute(i, minute);
    updateTzTimes();
  };
  const reorder = (startIndex: number, endIndex: number) => {
    logReorderTz();
    tzTime.reorder(startIndex, endIndex);
    updateTzTimes();
  };
  const deleteTz = (i: number) => {
    logDeleteTz(tzTimes[i].tz);
    tzTime.removeTz(i);
    updateTzTimes();
  };

  React.useEffect(() => {
    logScreenView("main");
    tzTime = queryToTzTimeClass(searchParams);

    // ブラウザ規定のタイムゾーンを、タイムゾーンリストの初期値に設定する。
    if (tzTime.getTzDataList().length === 0) {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const tz = findTzByName(timeZone);
      if (tz !== undefined) {
        tzTime.addTz(tz);
        tzTime.setTzMinute(0, hhmm2minute(format(new Date(), "HH:mm")));
      }
    }
    setTzTimes([...tzTime.getTzDataList()]);
  }, []);

  return (
    <Container maxWidth="md" sx={{ py: 2 }}>
      <Typography variant="h5" component="h1">
        tzdiff
      </Typography>
      <Typography variant="subtitle2" component="h2">
        Timezone converter
      </Typography>
      <Box sx={{ py: 2 }}>
        <TzSelect onSetTz={addTz}></TzSelect>
      </Box>
      <TzList tzTimes={tzTimes} setMinute={setMinute} reorder={reorder} deleteTz={deleteTz}></TzList>
    </Container>
  );
}
